import { useCallback } from 'react'
import {
  Document as Evidence,
  DOCUMENT_TYPE,
  AddDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useInvalidateEvidence } from '../../../Utils/swrCacheInvalidation'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../adapter/grpcClient'
import { showInfoToast } from '../../../Utils/helpers/toast'

interface CreateEvidenceArgs {
  controlId?: string
  requestId?: string
}

interface CreateEvidenceCallbackArgs {
  mime: string
  bodyBytes: Uint8Array
  caption: string
  relevantDate: Timestamp
  fileName?: string
}

export const useCreateEvidence = ({
  controlId,
  requestId,
}: CreateEvidenceArgs): (({
  mime,
  bodyBytes,
  caption,
  relevantDate,
  fileName,
}: CreateEvidenceCallbackArgs) => Promise<void>) => {
  const invalidateEvidence = useInvalidateEvidence()
  const attachmentClient = useAuthorizedGrpcClientWithContentUpdate(
    AttachmentPromiseClient,
  )
  return useCallback(
    async ({
      mime,
      bodyBytes,
      caption,
      relevantDate,
      fileName,
    }: CreateEvidenceCallbackArgs) => {
      const controlIdString = controlId || ''
      const requestIdString = requestId || ''

      const newEvidence = new Evidence()
        .setSubjectid(controlIdString)
        .setDoctype(DOCUMENT_TYPE.EVIDENCE)
        .setMime(mime)
        .setBody(bodyBytes)
        .setCaption(caption.trim())
        .setRelevantdate(relevantDate)
        .setRequestid(requestIdString)
        .setSubjectmodeltype(MODEL_TYPE.CONTROL)
      fileName && newEvidence.setFileName(fileName)

      try {
        await attachmentClient.addDocument(
          new AddDocumentRequest().setDocument(newEvidence),
        )
        await invalidateEvidence()
      } catch (error) {
        showInfoToast('Evidence upload failed')
      }
    },
    [attachmentClient, invalidateEvidence, controlId, requestId],
  )
}
