import React from 'react'
import {
  AUDIT_RESULT,
  AuditReadinessTestRecord,
  ControlTest,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  AuditBotFailIcon,
  AuditBotMissingGuidanceControlIcon,
  AuditBotMissingGuidanceIcon,
  AuditBotNAControlCheckIcon,
  AuditBotNAIcon,
  AuditBotPassIcon,
  AuditBotUnknownIcon,
  AuditBotUnknownXIcon,
  AuditBotXIcon,
} from 'src/pages/AuditBot/AuditBot.styles'
import {
  CONTROL_CHECK_ERROR_MAP,
  CONTROL_CHECK_ERRORS,
  CONTROL_NOT_APPLICABLE_REASON,
  HIDE_CONTROL_CHECK_REASONS,
  LAMBDA_FAILURE_ERROR_REASON,
} from 'src/pages/AuditBot/AuditBot.constants'
import {
  RoadmapCheckIcon,
  RoadmapNotScannedIcon,
} from 'src/pages/Roadmap/roadmap.styles'
import {
  AuditBotControlChecksType,
  CONTROL_TEST_NAMES,
  ControlResultCountsType,
} from './AuditBotControlChecks.constants'

export enum CHECK_RESULT_TYPE {
  OVERALL = 1,
  INDIVIDUAL = 2,
}

export const processControlChecks = (
  controlChecks: ControlTest[],
): Record<string, AuditBotControlChecksType> => {
  const checksByControl: Record<string, AuditBotControlChecksType> = {}
  controlChecks.forEach((controlCheck) => {
    if (!checksByControl[controlCheck.getControlId()]) {
      checksByControl[controlCheck.getControlId()] = {
        modelId: controlCheck.getControlModelId(),
        name: `${controlCheck.getControlModelId()} ${controlCheck.getControlName()}`,
        checks: [],
        isNotApplicable:
          controlCheck.getResult() === AUDIT_RESULT.NOT_APPLICABLE,
      }
    }
    checksByControl[controlCheck.getControlId()].checks.push(controlCheck)
  })
  return checksByControl
}

export const getControlResultCounts = (
  processedControlChecks: Record<string, AuditBotControlChecksType>,
): ControlResultCountsType => {
  const processedControlIds = Object.keys(processedControlChecks)
  const controlResultCounts: ControlResultCountsType = {
    [AUDIT_RESULT.PASS]: {
      count: 0,
      text: ' passed',
      key: AUDIT_RESULT.PASS,
    },
    [AUDIT_RESULT.FAIL]: {
      count: 0,
      text: ' had issues',
      key: AUDIT_RESULT.FAIL,
    },
    [AUDIT_RESULT.UNKNOWN]: {
      count: 0,
      text: ' could not be evaluated',
      key: AUDIT_RESULT.UNKNOWN,
    },
    [AUDIT_RESULT.MISSING_SUGGESTIONS]: {
      count: 0,
      text: ' were not evaluated because they were missing needed guidance',
      key: AUDIT_RESULT.MISSING_SUGGESTIONS,
    },
    [AUDIT_RESULT.NOT_APPLICABLE]: {
      count: 0,
      text: ' were not evaluated because they were not applicable',
      key: AUDIT_RESULT.NOT_APPLICABLE,
    },
    SUCCESSFUL: {
      count: 0,
      text: ' completed AI Control Checks',
    },
  }
  processedControlIds.forEach((controlId) => {
    const result = getCheckResult(
      processedControlChecks[controlId].checks.map((check) =>
        check.getResult(),
      ),
    )
    const successfulChecks = getSuccessfulChecks(
      processedControlChecks[controlId].checks,
    )
    controlResultCounts.SUCCESSFUL.count += successfulChecks
    switch (result) {
      case AUDIT_RESULT.NOT_APPLICABLE:
        controlResultCounts[AUDIT_RESULT.NOT_APPLICABLE].count += 1
        break
      case AUDIT_RESULT.PASS:
        controlResultCounts[AUDIT_RESULT.PASS].count += 1
        break
      case AUDIT_RESULT.FAIL:
        controlResultCounts[AUDIT_RESULT.FAIL].count += 1
        break
      case AUDIT_RESULT.MISSING_SUGGESTIONS:
        controlResultCounts[AUDIT_RESULT.MISSING_SUGGESTIONS].count += 1
        break
      case AUDIT_RESULT.LAMBDA_ERROR:
      case AUDIT_RESULT.UNKNOWN:
        controlResultCounts[AUDIT_RESULT.UNKNOWN].count += 1
        break
      default:
        break
    }
  })

  if (controlResultCounts[AUDIT_RESULT.NOT_APPLICABLE].count === 1) {
    controlResultCounts[AUDIT_RESULT.NOT_APPLICABLE].text =
      ' was not evaluated because it was not applicable'
  }

  if (controlResultCounts[AUDIT_RESULT.MISSING_SUGGESTIONS].count === 1) {
    controlResultCounts[AUDIT_RESULT.MISSING_SUGGESTIONS].text =
      ' was not evaluated because it was missing needed guidance'
  }

  return controlResultCounts
}

const getSuccessfulChecks = (checks: ControlTest[]) => {
  let res = 0
  checks.forEach((check) => {
    if (
      check.getError() === '' &&
      check.getReason() !== HIDE_CONTROL_CHECK_REASONS.NOT_APPLICABLE_AUDIT
    ) {
      res += 1
    }
  })

  return res
}

export const getCheckResult = (results: AUDIT_RESULT[]): AUDIT_RESULT => {
  // if any are failing, return fail
  if (results.some((result) => result === AUDIT_RESULT.FAIL)) {
    return AUDIT_RESULT.FAIL
    // if any are missing suggestions, return missing suggestions
  } else if (
    results.some((result) => result === AUDIT_RESULT.MISSING_SUGGESTIONS)
  ) {
    return AUDIT_RESULT.MISSING_SUGGESTIONS
    // if any are not run, return not run
  } else if (results.some((result) => result === AUDIT_RESULT.NOT_RUN)) {
    return AUDIT_RESULT.NOT_RUN
    // if any are lambda error or unknown, return unknown
  } else if (
    results.some((result: AUDIT_RESULT) => result === AUDIT_RESULT.LAMBDA_ERROR)
  ) {
    return AUDIT_RESULT.LAMBDA_ERROR
  } else if (
    results.some((result: AUDIT_RESULT) => result === AUDIT_RESULT.UNKNOWN)
  ) {
    return AUDIT_RESULT.UNKNOWN
    // if all are not applicable, return not applicable
  } else if (
    results.every(
      (result: AUDIT_RESULT) => result === AUDIT_RESULT.NOT_APPLICABLE,
    )
  ) {
    return AUDIT_RESULT.NOT_APPLICABLE
    // if all are passing or not applicable, return pass
  } else if (
    results.every((result: AUDIT_RESULT) => {
      return (
        result === AUDIT_RESULT.PASS || result === AUDIT_RESULT.NOT_APPLICABLE
      )
    })
  ) {
    return AUDIT_RESULT.PASS
  }
  // otherwise, return unknown
  return AUDIT_RESULT.UNKNOWN
}

export const getControlCheckOtherIcon = (result: AUDIT_RESULT): JSX.Element => {
  switch (result) {
    case AUDIT_RESULT.NOT_APPLICABLE:
      return <AuditBotNAIcon />
    default:
      return <AuditBotUnknownXIcon />
  }
}

export const getControlCheckOtherReason = (result: AUDIT_RESULT): string => {
  switch (result) {
    case AUDIT_RESULT.NOT_APPLICABLE:
      return CONTROL_NOT_APPLICABLE_REASON
    default:
      return LAMBDA_FAILURE_ERROR_REASON
  }
}

export const getCheckResultWithStale = (
  results: AuditReadinessTestRecord[],
  totalTests: number,
): { result: AUDIT_RESULT; isStale: boolean; isMissingChecks: boolean } => {
  let notApplicableCount = 0
  let passCount = 0
  let hasFail = false
  let isStale = false

  for (const test of results) {
    if (!isStale && test.getIsStale()) {
      isStale = true
    }

    switch (test.getResult()) {
      case AUDIT_RESULT.NOT_APPLICABLE:
        notApplicableCount++
        break
      case AUDIT_RESULT.PASS:
        passCount++
        break
      case AUDIT_RESULT.FAIL:
        hasFail = true
        break
      default:
        break
    }
  }

  const isMissingChecks = results.length < totalTests

  if (results.length === 0) {
    return { result: AUDIT_RESULT.NOT_RUN, isStale, isMissingChecks }
  } else if (notApplicableCount === results.length) {
    return { result: AUDIT_RESULT.NOT_APPLICABLE, isStale, isMissingChecks }
  } else if (passCount === results.length) {
    return { result: AUDIT_RESULT.PASS, isStale, isMissingChecks }
  } else if (hasFail) {
    return { result: AUDIT_RESULT.FAIL, isStale, isMissingChecks }
  }
  return { result: AUDIT_RESULT.UNKNOWN, isStale, isMissingChecks }
}

export const getCheckResultIcon = (
  result: AUDIT_RESULT,
  resultType: CHECK_RESULT_TYPE,
): JSX.Element => {
  const isControlResult = resultType === CHECK_RESULT_TYPE.OVERALL
  switch (result) {
    case AUDIT_RESULT.PASS:
      return isControlResult ? <AuditBotPassIcon /> : <RoadmapCheckIcon />
    case AUDIT_RESULT.FAIL:
      return isControlResult ? <AuditBotFailIcon /> : <AuditBotXIcon />
    case AUDIT_RESULT.LAMBDA_ERROR:
    case AUDIT_RESULT.UNKNOWN:
      return isControlResult ? (
        <AuditBotUnknownIcon />
      ) : (
        <AuditBotUnknownXIcon />
      )
    case AUDIT_RESULT.NOT_APPLICABLE:
      return <AuditBotNAControlCheckIcon />
    case AUDIT_RESULT.MISSING_SUGGESTIONS:
      return isControlResult ? (
        <AuditBotMissingGuidanceIcon />
      ) : (
        <AuditBotMissingGuidanceControlIcon />
      )
    default:
      return <RoadmapNotScannedIcon />
  }
}

export const getSingleCheckResultText = (result: AUDIT_RESULT): string => {
  switch (result) {
    case AUDIT_RESULT.PASS:
      return 'Pass'
    case AUDIT_RESULT.FAIL:
      return 'Fail'
    case AUDIT_RESULT.LAMBDA_ERROR:
    case AUDIT_RESULT.UNKNOWN:
      return 'Unknown'
    case AUDIT_RESULT.NOT_APPLICABLE:
      return 'Not Applicable'
    case AUDIT_RESULT.MISSING_SUGGESTIONS:
      return 'Missing Needed Guidance'
    default:
      return 'Not Scanned'
  }
}

export const getReasonText = (reason: string): string =>
  CONTROL_CHECK_ERROR_MAP[reason as CONTROL_CHECK_ERRORS] || reason

export const getOrderedControlChecks = (
  controlChecks: ControlTest[],
): ControlTest[] => {
  const priorityChecks: Record<CONTROL_TEST_NAMES, ControlTest | undefined> = {
    [CONTROL_TEST_NAMES.POLICY_MATCH]: undefined,
    [CONTROL_TEST_NAMES.COMPLETENESS]: undefined,
    [CONTROL_TEST_NAMES.SPOT_CHECK]: undefined,
    // below are not used today
    [CONTROL_TEST_NAMES.ONBOARDING]: undefined,
    [CONTROL_TEST_NAMES.OFFBOARDING]: undefined,
    [CONTROL_TEST_NAMES.ONGOING_EMPLOYEES]: undefined,
  }
  const filtered = controlChecks.filter((check) => {
    const testName = check?.getTestName()
    if (testName in priorityChecks) {
      priorityChecks[testName as CONTROL_TEST_NAMES] = check
      return false
    }
    return true
  })
  return [
    ...Object.values(priorityChecks).filter(
      (check): check is ControlTest => check !== undefined,
    ),
    ...filtered,
  ]
}
