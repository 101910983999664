import React, { useCallback } from 'react'
import {
  Question,
  UploadQuestionnaireRequest,
  GetQuestionnaireRequest,
  Questionnaire,
  GetQuestionnairesResponse,
  DeleteQuestionnaireRequest,
  CreateOrUpdateAnswerRequest,
  Answer,
  QUESTIONNAIRE_ANSWER_TYPE,
  GetAccountBalanceResponse,
  ValidateAccountBalanceRequest,
  AnswerAgainRequest,
  AnswerAgainResponse,
  AddSqToKbRequest,
  UploadKnowledgeBaseRequest,
  UploadQuestionnaireResponse,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { QuestionnaireServicePromiseClient } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_grpc_web_pb'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useGrpcRevalidateByMethod } from 'src/components'
import log from 'loglevel'
import { useSwrGrpc } from 'src/components/async/useSwrGrpc'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { useConfirmationModal } from 'src/components/ModalForms'
import {
  HardLimitBody,
  SoftLimitBody,
} from 'src/components/Reusable/Cost/Cost.components'
import { useAuth } from 'src/context/authContext'
import { useThrobberContext } from 'src/Throbber'
import { ALLOW_TRANSACTION } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useAuthorizedGrpcClientWithContentUpdate } from 'src/adapter/grpcClient'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import {
  BuyerOrigin,
  SendWelcomeEmailRequest,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { runAuditBotAnimation } from '../AuditBot/AuditBot.helpers'
import { getQuestionnaireAsFile } from './modals/securityQuestionnaireModals.helpers'
import { useAnswerQuestionQuestionnaire } from './askQuestion/AnswerQuestion.context'

type QuestionsType = {
  question: string
  answer?: string
}

export const useUploadSecurityQuestionnaire = (
  hideThrobber?: boolean,
): (({
  questions,
  name,
  org,
  dueDate,
  answerType,
}: {
  questions: QuestionsType[]
  answerType: QUESTIONNAIRE_ANSWER_TYPE
  name?: string
  org?: string
  dueDate?: Timestamp
}) => Promise<UploadQuestionnaireResponse | undefined>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )
  const { events } = useAnalytics()
  const { accountId } = useAuth()
  const { setThrobberState } = useThrobberContext()
  const mutate = useInvalidateQuestionnaireCache()
  const { setActiveQuestionnaireId } = useAnswerQuestionQuestionnaire()

  const softLimit = useConfirmationModal<
    [
      QuestionsType[],
      string | undefined,
      string | undefined,
      Timestamp | undefined,
      QUESTIONNAIRE_ANSWER_TYPE,
      true,
    ]
  >({
    title: "Let's Keep Trustero AI Running Smoothly!",
    body: <SoftLimitBody />,
    confirmText: 'Continue with Questionnaire',
    analyticsEvent: events.V_SECURITY_QUESTIONNAIRE_SOFT_LIMIT,
    analyticsEventData: {
      accountId,
    },
    onConfirmCB: async (
      questions,
      name,
      org,
      dueDate,
      answerType,
      skipAccountValidation,
    ) => {
      try {
        const res = await fireSecurityQuestionnaire({
          questions,
          name,
          org,
          dueDate,
          answerType,
          skipAccountValidation,
        })

        if (res) {
          setActiveQuestionnaireId(res.getQuestionnaireId())
        }
      } catch (err) {
        log.error(err)
      }
    },
  })

  const hardLimit = useConfirmationModal({
    title: 'Time to Get Trustero AI Back in Action!',
    body: <HardLimitBody />,
    hideCancel: true,
    confirmText: 'Dismiss',
    analyticsEvent: events.V_SECURITY_QUESTIONNAIRE_HARD_LIMIT,
    analyticsEventData: {
      accountId,
    },
  })

  const fireSecurityQuestionnaire = useCallback(
    async ({
      questions,
      name,
      org,
      dueDate,
      answerType,
      skipAccountValidation,
    }: {
      questions: QuestionsType[]
      name?: string
      org?: string
      dueDate?: Timestamp
      answerType: QUESTIONNAIRE_ANSWER_TYPE
      skipAccountValidation?: boolean
    }): Promise<UploadQuestionnaireResponse | undefined> => {
      !hideThrobber && runAuditBotAnimation(setThrobberState)

      if (!skipAccountValidation) {
        const validationRequest =
          new ValidateAccountBalanceRequest().setQuestionnaireAnswerType(
            answerType,
          )
        const accountValidationResponse =
          await questionnaireClient.validateAccountBalance(validationRequest)
        const allowKb = accountValidationResponse.getAllowKnowledgeBase()
        const allowPlatform = accountValidationResponse.getAllowPlatform()

        if (
          allowKb === ALLOW_TRANSACTION.HARD_BLOCK ||
          (answerType !== QUESTIONNAIRE_ANSWER_TYPE.KNOWLEDGE_BASE &&
            allowPlatform === ALLOW_TRANSACTION.HARD_BLOCK)
        ) {
          hardLimit()
          return
        } else if (
          allowKb === ALLOW_TRANSACTION.SOFT_BLOCK ||
          allowPlatform === ALLOW_TRANSACTION.SOFT_BLOCK
        ) {
          softLimit(questions, name, org, dueDate, answerType, true)
          return
        }
      }
      const requestQuestions = questions.map((elem) =>
        new Question().setAnswer(elem.answer || '').setQuestion(elem.question),
      )

      const request = new UploadQuestionnaireRequest()
      request.setName(name || '')
      request.setOrganization(org || '')
      request.setQuestionsList(requestQuestions)
      request.setDueDate(dueDate)
      request.setQuestionnaireAnswerType(answerType)
      const res = await questionnaireClient.uploadQuestionnaire(request)
      await mutate()
      return res
    },
    [
      hardLimit,
      questionnaireClient,
      setThrobberState,
      softLimit,
      mutate,
      hideThrobber,
    ],
  )

  return fireSecurityQuestionnaire
}

export const useUpdateAnswer = (): ((answer: Answer) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (answer: Answer) => {
    const req = new CreateOrUpdateAnswerRequest().setAnswer(answer)
    await questionnaireClient.createOrUpdateAnswer(req)
  }
}

export const useAddToKnowledgeBase = (): ((
  questionnaire: Questionnaire,
) => Promise<void>) => {
  const mutate = useInvalidateQuestionnaireCache()
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (questionnaire) => {
    try {
      const file = await getQuestionnaireAsFile(questionnaire)
      const questionCount = questionnaire.getQuestionsList().length

      const request = new AddSqToKbRequest()
        .setKnowledgeBase(
          new UploadKnowledgeBaseRequest()
            .setFile(file)
            .setFileName(questionnaire.getName())
            .setQuestionCount(questionCount)
            .setAnswerCount(questionCount),
        )
        .setSecurityQuestionnaireId(questionnaire.getId())
      await questionnaireClient.addQuestionnaireToKnowledgeBase(request)
      mutate()
    } catch (err) {
      log.error(
        'error when attempting to add questionnaire to knowledge base id:',
        questionnaire.getId(),
      )
    }
  }
}

export const useUpdateAnswerFromQuestion = (): ((
  question: Question,
) => Promise<void>) => {
  const updateFunc = useUpdateAnswer()

  return async (question: Question) => {
    const answer = new Answer()
    answer
      .setId(question.getAnswerId())
      .setAnswer(question.getAnswer())
      .setAcceptance(question.getAcceptance())
      .setStatus(question.getStatus())
      .setQuestionId(question.getId())

    await updateFunc(answer)
  }
}

export const useGetQuestionnaires =
  (): GrpcResponse<GetQuestionnairesResponse> => {
    const { response } = useSwrImmutableGrpc(
      QuestionnaireServicePromiseClient.prototype.getQuestionnaires,
      new Empty(),
    )

    return NewGrpcResponse(response)
  }

export const useGetQuestionnaire = ({
  id,
  allowFetch = true,
  includeDeleted = false,
}: {
  id?: string
  allowFetch?: boolean
  includeDeleted?: boolean
}): GrpcResponse<Questionnaire> => {
  const shouldFetch = !!(id && id.length) && allowFetch
  const request = new GetQuestionnaireRequest().setId(id || '')
  includeDeleted &&
    request.setIncludeDeleted(new BoolValue().setValue(includeDeleted))
  const { response } = useSwrImmutableGrpc(
    QuestionnaireServicePromiseClient.prototype.getQuestionnaire,
    request,
    shouldFetch,
  )

  return NewGrpcResponse(response)
}

export const useDeleteQuestionnaire = (): ((id: string) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (id: string) => {
    const req = new DeleteQuestionnaireRequest().setId(id)
    await questionnaireClient.deleteQuestionnaire(req)
  }
}

export const useUpdateQuestionnaire = (): ((
  questionnaire: Questionnaire,
) => Promise<void>) => {
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )
  const mutate = useInvalidateQuestionnaireCache()

  return useCallback(
    async (questionnaire: Questionnaire) => {
      await questionnaireClient.updateQuestionnaire(questionnaire)
      await mutate()
    },
    [questionnaireClient, mutate],
  )
}

export const useAnswerQuestionAgain = (
  question: Question,
): (() => Promise<AnswerAgainResponse>) => {
  // we will refetch the questionnaire after starting the task and will get the loading state for free
  const { mutate } = useGetQuestionnaire({ id: question.getQuestionnaireId() })
  const questionnaireClient = useAuthorizedGrpcClient(
    QuestionnaireServicePromiseClient,
  )

  return async (): Promise<AnswerAgainResponse> => {
    const request = new AnswerAgainRequest().setQuestion(question)
    const response = await questionnaireClient.answerQuestionAgain(request)
    await mutate()
    return response
  }
}

// to *slightly* improve our cache invalidation strategy
// we will determine what route the user is on to invalidate
// the correct RPC
export const useInvalidateQuestionnaireCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    const request = window.location.href.includes('index')
      ? QuestionnaireServicePromiseClient.prototype.getQuestionnaires
      : QuestionnaireServicePromiseClient.prototype.getQuestionnaire

    try {
      await mutateFunc(request)
    } catch (err) {
      log.error('Error when invalidating questionnaire cache', err)
    }
  }, [mutateFunc])
}

export const useSecurityQuestionnaireBalance =
  (): GrpcResponse<GetAccountBalanceResponse> => {
    const { response } = useSwrGrpc(
      QuestionnaireServicePromiseClient.prototype.getAccountBalance,
      new Empty(),
    )

    return NewGrpcResponse(response)
  }

export const useSendWelcomeEmail = (): ((email: string) => Promise<void>) => {
  const AccountClient =
    useAuthorizedGrpcClientWithContentUpdate(AccountPromiseClient)
  return async (email: string) => {
    try {
      const request = new SendWelcomeEmailRequest()
        .setEmail(email)
        .setBuyerOrigin(BuyerOrigin.SECURITY_QUESTIONNAIRE)
      await AccountClient.sendSaasBuyerWelcomeEmail(request)
    } catch (e) {
      log.error(
        `Error in security questionnaire hooks send welcome email ${email}`,
        e,
      )
    }
  }
}
