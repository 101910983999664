import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Location } from 'history'
import queryString, { ParsedQuery } from 'query-string'
import Dropdown from 'react-bootstrap/Dropdown'
import { MenuPlaceholder } from 'src/components/Placeholders/MenuPlaceholder'
import isFunction from 'lodash/isFunction'
import { PERMISSIONS } from 'src/config/roleConfig'
import { resetIndexPageNumber } from 'src/Utils/globalHelpers'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { CountChip } from '../../../Chips/BasicChip'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'
import { DropdownItemType } from './FilterDropdown.constants'

type FilterDropdownBaseProps = {
  filterParam: FilterParam
  filterName: FilterName
  filterValues: DropdownItemType[]
  isLoading?: boolean
}

type FilterDropdownConditionalProps =
  | {
      show?: boolean
      handleToggle?: () => void
    }
  | {
      show?: never
      handleToggle?: never
    }

type FilterDropdownProps = FilterDropdownBaseProps &
  FilterDropdownConditionalProps

export function FilterDropdown({
  filterParam,
  filterName,
  filterValues,
  isLoading,
  show,
  handleToggle,
}: FilterDropdownProps): JSX.Element {
  const location = useLocation()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])
  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams, filterParam])

  const isSmartCheckFilter = filterParam === FilterParam.AI_CONTROL_CHECKS

  return (
    <Dropdown autoClose="outside" show={show} onToggle={handleToggle}>
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        $isAi={isSmartCheckFilter}
        id={`${filterName}-dropdown`}
      >
        <p>{filterName}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      {isLoading ? (
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu>
          <DropdownItems
            filterParam={filterParam}
            filterValues={filterValues}
            location={location}
            searchParams={searchParams}
            appliedFilters={appliedFilters}
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}

function DropdownItems({
  filterParam,
  filterValues,
  location,
  searchParams,
  appliedFilters,
}: {
  filterParam: FilterParam
  filterValues: DropdownItemType[]
  location: Location
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element {
  const navigate = useNavigate()
  // if we are applying a filter on a paginated index page, we should reset the page to 1
  const baseQuery = resetIndexPageNumber(searchParams)

  return (
    <>
      {filterValues.map((filterValue) => {
        const newAppliedFilters = new Set(appliedFilters)
        const isChecked = newAppliedFilters.has(filterValue.value)
        const isCustom = isFunction(filterValue.customOnClick)

        isChecked
          ? newAppliedFilters.delete(filterValue.value)
          : newAppliedFilters.add(filterValue.value)

        const href = queryString.stringifyUrl(
          {
            url: location.pathname,
            query: {
              ...baseQuery,
              [filterParam]: Array.from(newAppliedFilters),
            },
          },
          { arrayFormat: 'bracket' },
        )

        return (
          <FilterDropdownItem
            key={filterValue.value}
            replace
            to={href}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              if (isCustom) {
                e.preventDefault()
                filterValue.customOnClick?.()
              }
            }}
          >
            <CheckBoxInput
              id={filterValue.value}
              label={filterValue.label as React.ReactNode}
              checked={isChecked}
              onClick={(e) => {
                e.stopPropagation()
                if (isCustom) {
                  filterValue.customOnClick?.()
                  return
                }
              }}
              onChange={() => {
                if (isCustom) {
                  return
                }
                navigate(href, { replace: true })
              }}
              tabIndex={-1}
              requiredPermissions={[PERMISSIONS.READ]}
            />
          </FilterDropdownItem>
        )
      })}
    </>
  )
}
