// source: questionnaire/questionnaire.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
goog.exportSymbol('proto.questionnaire.ANSWER_ACCEPTANCE', null, global);
goog.exportSymbol('proto.questionnaire.ANSWER_STATUS', null, global);
goog.exportSymbol('proto.questionnaire.ANSWER_TYPE', null, global);
goog.exportSymbol('proto.questionnaire.AddSqToKbRequest', null, global);
goog.exportSymbol('proto.questionnaire.Answer', null, global);
goog.exportSymbol('proto.questionnaire.AnswerAgainRequest', null, global);
goog.exportSymbol('proto.questionnaire.AnswerAgainResponse', null, global);
goog.exportSymbol('proto.questionnaire.AnswerIdResponse', null, global);
goog.exportSymbol('proto.questionnaire.Answer_Sources', null, global);
goog.exportSymbol('proto.questionnaire.CreateOrUpdateAnswerRequest', null, global);
goog.exportSymbol('proto.questionnaire.DeleteQuestionnaireRequest', null, global);
goog.exportSymbol('proto.questionnaire.GetAccountBalanceResponse', null, global);
goog.exportSymbol('proto.questionnaire.GetKnowledgeBasesResponse', null, global);
goog.exportSymbol('proto.questionnaire.GetQuestionnaireRequest', null, global);
goog.exportSymbol('proto.questionnaire.GetQuestionnairesResponse', null, global);
goog.exportSymbol('proto.questionnaire.IndexQuestionnaire', null, global);
goog.exportSymbol('proto.questionnaire.KNOWLEDGE_BASE_SOURCE', null, global);
goog.exportSymbol('proto.questionnaire.KnowledgeBase', null, global);
goog.exportSymbol('proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE', null, global);
goog.exportSymbol('proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE', null, global);
goog.exportSymbol('proto.questionnaire.QUESTIONNAIRE_STATUS', null, global);
goog.exportSymbol('proto.questionnaire.QUESTIONNAIRE_TYPE', null, global);
goog.exportSymbol('proto.questionnaire.Question', null, global);
goog.exportSymbol('proto.questionnaire.Questionnaire', null, global);
goog.exportSymbol('proto.questionnaire.QuestionnaireIdResponse', null, global);
goog.exportSymbol('proto.questionnaire.UploadKnowledgeBaseRequest', null, global);
goog.exportSymbol('proto.questionnaire.UploadQuestionnaireRequest', null, global);
goog.exportSymbol('proto.questionnaire.UploadQuestionnaireResponse', null, global);
goog.exportSymbol('proto.questionnaire.ValidateAccountBalanceRequest', null, global);
goog.exportSymbol('proto.questionnaire.ValidateAccountBalanceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.GetAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.GetAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.GetAccountBalanceResponse.displayName = 'proto.questionnaire.GetAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.ValidateAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.ValidateAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.ValidateAccountBalanceResponse.displayName = 'proto.questionnaire.ValidateAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.ValidateAccountBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.ValidateAccountBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.ValidateAccountBalanceRequest.displayName = 'proto.questionnaire.ValidateAccountBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.Questionnaire = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.questionnaire.Questionnaire.repeatedFields_, null);
};
goog.inherits(proto.questionnaire.Questionnaire, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.Questionnaire.displayName = 'proto.questionnaire.Questionnaire';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.GetKnowledgeBasesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.questionnaire.GetKnowledgeBasesResponse.repeatedFields_, null);
};
goog.inherits(proto.questionnaire.GetKnowledgeBasesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.GetKnowledgeBasesResponse.displayName = 'proto.questionnaire.GetKnowledgeBasesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.KnowledgeBase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.KnowledgeBase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.KnowledgeBase.displayName = 'proto.questionnaire.KnowledgeBase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.IndexQuestionnaire = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.IndexQuestionnaire, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.IndexQuestionnaire.displayName = 'proto.questionnaire.IndexQuestionnaire';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.QuestionnaireIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.QuestionnaireIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.QuestionnaireIdResponse.displayName = 'proto.questionnaire.QuestionnaireIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.AnswerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.AnswerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.AnswerIdResponse.displayName = 'proto.questionnaire.AnswerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.AnswerAgainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.AnswerAgainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.AnswerAgainRequest.displayName = 'proto.questionnaire.AnswerAgainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.AnswerAgainResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.AnswerAgainResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.AnswerAgainResponse.displayName = 'proto.questionnaire.AnswerAgainResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.GetQuestionnairesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.questionnaire.GetQuestionnairesResponse.repeatedFields_, null);
};
goog.inherits(proto.questionnaire.GetQuestionnairesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.GetQuestionnairesResponse.displayName = 'proto.questionnaire.GetQuestionnairesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.Answer_Sources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.Answer_Sources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.Answer_Sources.displayName = 'proto.questionnaire.Answer_Sources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.questionnaire.Question.repeatedFields_, null);
};
goog.inherits(proto.questionnaire.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.Question.displayName = 'proto.questionnaire.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.UploadKnowledgeBaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.UploadKnowledgeBaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.UploadKnowledgeBaseRequest.displayName = 'proto.questionnaire.UploadKnowledgeBaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.UploadQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.questionnaire.UploadQuestionnaireRequest.repeatedFields_, null);
};
goog.inherits(proto.questionnaire.UploadQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.UploadQuestionnaireRequest.displayName = 'proto.questionnaire.UploadQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.AddSqToKbRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.AddSqToKbRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.AddSqToKbRequest.displayName = 'proto.questionnaire.AddSqToKbRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.UploadQuestionnaireResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.UploadQuestionnaireResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.UploadQuestionnaireResponse.displayName = 'proto.questionnaire.UploadQuestionnaireResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.GetQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.GetQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.GetQuestionnaireRequest.displayName = 'proto.questionnaire.GetQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.DeleteQuestionnaireRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.DeleteQuestionnaireRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.DeleteQuestionnaireRequest.displayName = 'proto.questionnaire.DeleteQuestionnaireRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.Answer.displayName = 'proto.questionnaire.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.questionnaire.CreateOrUpdateAnswerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.questionnaire.CreateOrUpdateAnswerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.questionnaire.CreateOrUpdateAnswerRequest.displayName = 'proto.questionnaire.CreateOrUpdateAnswerRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.GetAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.GetAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    kbBalance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platformBalance: jspb.Message.getFieldWithDefault(msg, 2, 0),
    kbAllocationTotal: jspb.Message.getFieldWithDefault(msg, 3, 0),
    platformAllocationTotal: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.GetAccountBalanceResponse}
 */
proto.questionnaire.GetAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.GetAccountBalanceResponse;
  return proto.questionnaire.GetAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.GetAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.GetAccountBalanceResponse}
 */
proto.questionnaire.GetAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKbBalance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlatformBalance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKbAllocationTotal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlatformAllocationTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.GetAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.GetAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKbBalance();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlatformBalance();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getKbAllocationTotal();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPlatformAllocationTotal();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 kb_balance = 1;
 * @return {number}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.getKbBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.GetAccountBalanceResponse} returns this
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.setKbBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 platform_balance = 2;
 * @return {number}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.getPlatformBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.GetAccountBalanceResponse} returns this
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.setPlatformBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 kb_allocation_total = 3;
 * @return {number}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.getKbAllocationTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.GetAccountBalanceResponse} returns this
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.setKbAllocationTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 platform_allocation_total = 4;
 * @return {number}
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.getPlatformAllocationTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.GetAccountBalanceResponse} returns this
 */
proto.questionnaire.GetAccountBalanceResponse.prototype.setPlatformAllocationTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.ValidateAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.ValidateAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.ValidateAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowKnowledgeBase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allowPlatform: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.ValidateAccountBalanceResponse}
 */
proto.questionnaire.ValidateAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.ValidateAccountBalanceResponse;
  return proto.questionnaire.ValidateAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.ValidateAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.ValidateAccountBalanceResponse}
 */
proto.questionnaire.ValidateAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.ALLOW_TRANSACTION} */ (reader.readEnum());
      msg.setAllowKnowledgeBase(value);
      break;
    case 2:
      var value = /** @type {!proto.common.ALLOW_TRANSACTION} */ (reader.readEnum());
      msg.setAllowPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.ValidateAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.ValidateAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.ValidateAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowKnowledgeBase();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAllowPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional common.ALLOW_TRANSACTION allow_knowledge_base = 1;
 * @return {!proto.common.ALLOW_TRANSACTION}
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.getAllowKnowledgeBase = function() {
  return /** @type {!proto.common.ALLOW_TRANSACTION} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.ALLOW_TRANSACTION} value
 * @return {!proto.questionnaire.ValidateAccountBalanceResponse} returns this
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.setAllowKnowledgeBase = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional common.ALLOW_TRANSACTION allow_platform = 2;
 * @return {!proto.common.ALLOW_TRANSACTION}
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.getAllowPlatform = function() {
  return /** @type {!proto.common.ALLOW_TRANSACTION} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.ALLOW_TRANSACTION} value
 * @return {!proto.questionnaire.ValidateAccountBalanceResponse} returns this
 */
proto.questionnaire.ValidateAccountBalanceResponse.prototype.setAllowPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.ValidateAccountBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.ValidateAccountBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.ValidateAccountBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.ValidateAccountBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnaireAnswerType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.ValidateAccountBalanceRequest}
 */
proto.questionnaire.ValidateAccountBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.ValidateAccountBalanceRequest;
  return proto.questionnaire.ValidateAccountBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.ValidateAccountBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.ValidateAccountBalanceRequest}
 */
proto.questionnaire.ValidateAccountBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} */ (reader.readEnum());
      msg.setQuestionnaireAnswerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.ValidateAccountBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.ValidateAccountBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.ValidateAccountBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.ValidateAccountBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnaireAnswerType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional QUESTIONNAIRE_ANSWER_TYPE questionnaire_answer_type = 1;
 * @return {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE}
 */
proto.questionnaire.ValidateAccountBalanceRequest.prototype.getQuestionnaireAnswerType = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} value
 * @return {!proto.questionnaire.ValidateAccountBalanceRequest} returns this
 */
proto.questionnaire.ValidateAccountBalanceRequest.prototype.setQuestionnaireAnswerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.questionnaire.Questionnaire.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.Questionnaire.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.Questionnaire.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.Questionnaire} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Questionnaire.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 4, ""),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.questionnaire.Question.toObject, includeInstance),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    acceptance: jspb.Message.getFieldWithDefault(msg, 8, 0),
    assignee: jspb.Message.getFieldWithDefault(msg, 9, ""),
    questionCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    knowledgeBaseId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    questionnaireType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    startedAt: (f = msg.getStartedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completedAt: (f = msg.getCompletedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.Questionnaire}
 */
proto.questionnaire.Questionnaire.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.Questionnaire;
  return proto.questionnaire.Questionnaire.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.Questionnaire} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.Questionnaire}
 */
proto.questionnaire.Questionnaire.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 5:
      var value = new proto.questionnaire.Question;
      reader.readMessage(value,proto.questionnaire.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} */ (reader.readEnum());
      msg.setAcceptance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignee(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionCount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setKnowledgeBaseId(value);
      break;
    case 13:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_TYPE} */ (reader.readEnum());
      msg.setQuestionnaireType(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.Questionnaire.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.Questionnaire.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.Questionnaire} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Questionnaire.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.questionnaire.Question.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAcceptance();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getAssignee();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQuestionCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getKnowledgeBaseId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getQuestionnaireType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getStartedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompletedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.Questionnaire.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.questionnaire.Questionnaire.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization = 4;
 * @return {string}
 */
proto.questionnaire.Questionnaire.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Question questions = 5;
 * @return {!Array<!proto.questionnaire.Question>}
 */
proto.questionnaire.Questionnaire.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.questionnaire.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.questionnaire.Question, 5));
};


/**
 * @param {!Array<!proto.questionnaire.Question>} value
 * @return {!proto.questionnaire.Questionnaire} returns this
*/
proto.questionnaire.Questionnaire.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.questionnaire.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.questionnaire.Question}
 */
proto.questionnaire.Questionnaire.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.questionnaire.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional google.protobuf.Timestamp due_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.Questionnaire.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.Questionnaire} returns this
*/
proto.questionnaire.Questionnaire.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.Questionnaire.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.Questionnaire.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.Questionnaire} returns this
*/
proto.questionnaire.Questionnaire.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.Questionnaire.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional QUESTIONNAIRE_STATUS status = 7;
 * @return {!proto.questionnaire.QUESTIONNAIRE_STATUS}
 */
proto.questionnaire.Questionnaire.prototype.getStatus = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_STATUS} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_STATUS} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional QUESTIONNAIRE_ACCEPTANCE acceptance = 8;
 * @return {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE}
 */
proto.questionnaire.Questionnaire.prototype.getAcceptance = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setAcceptance = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string assignee = 9;
 * @return {string}
 */
proto.questionnaire.Questionnaire.prototype.getAssignee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 question_count = 10;
 * @return {number}
 */
proto.questionnaire.Questionnaire.prototype.getQuestionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setQuestionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_deleted = 11;
 * @return {boolean}
 */
proto.questionnaire.Questionnaire.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string knowledge_base_id = 12;
 * @return {string}
 */
proto.questionnaire.Questionnaire.prototype.getKnowledgeBaseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setKnowledgeBaseId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional QUESTIONNAIRE_TYPE questionnaire_type = 13;
 * @return {!proto.questionnaire.QUESTIONNAIRE_TYPE}
 */
proto.questionnaire.Questionnaire.prototype.getQuestionnaireType = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_TYPE} value
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.setQuestionnaireType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp started_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.Questionnaire.prototype.getStartedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.Questionnaire} returns this
*/
proto.questionnaire.Questionnaire.prototype.setStartedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.clearStartedAt = function() {
  return this.setStartedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.Questionnaire.prototype.hasStartedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp completed_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.Questionnaire.prototype.getCompletedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.Questionnaire} returns this
*/
proto.questionnaire.Questionnaire.prototype.setCompletedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.Questionnaire} returns this
 */
proto.questionnaire.Questionnaire.prototype.clearCompletedAt = function() {
  return this.setCompletedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.Questionnaire.prototype.hasCompletedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.questionnaire.GetKnowledgeBasesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.GetKnowledgeBasesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.GetKnowledgeBasesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.GetKnowledgeBasesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetKnowledgeBasesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    knowledgeBasesList: jspb.Message.toObjectList(msg.getKnowledgeBasesList(),
    proto.questionnaire.KnowledgeBase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.GetKnowledgeBasesResponse}
 */
proto.questionnaire.GetKnowledgeBasesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.GetKnowledgeBasesResponse;
  return proto.questionnaire.GetKnowledgeBasesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.GetKnowledgeBasesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.GetKnowledgeBasesResponse}
 */
proto.questionnaire.GetKnowledgeBasesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.KnowledgeBase;
      reader.readMessage(value,proto.questionnaire.KnowledgeBase.deserializeBinaryFromReader);
      msg.addKnowledgeBases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.GetKnowledgeBasesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.GetKnowledgeBasesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.GetKnowledgeBasesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetKnowledgeBasesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKnowledgeBasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.questionnaire.KnowledgeBase.serializeBinaryToWriter
    );
  }
};


/**
 * repeated KnowledgeBase knowledge_bases = 1;
 * @return {!Array<!proto.questionnaire.KnowledgeBase>}
 */
proto.questionnaire.GetKnowledgeBasesResponse.prototype.getKnowledgeBasesList = function() {
  return /** @type{!Array<!proto.questionnaire.KnowledgeBase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.questionnaire.KnowledgeBase, 1));
};


/**
 * @param {!Array<!proto.questionnaire.KnowledgeBase>} value
 * @return {!proto.questionnaire.GetKnowledgeBasesResponse} returns this
*/
proto.questionnaire.GetKnowledgeBasesResponse.prototype.setKnowledgeBasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.questionnaire.KnowledgeBase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.questionnaire.KnowledgeBase}
 */
proto.questionnaire.GetKnowledgeBasesResponse.prototype.addKnowledgeBases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.questionnaire.KnowledgeBase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.questionnaire.GetKnowledgeBasesResponse} returns this
 */
proto.questionnaire.GetKnowledgeBasesResponse.prototype.clearKnowledgeBasesList = function() {
  return this.setKnowledgeBasesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.KnowledgeBase.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.KnowledgeBase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.KnowledgeBase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.KnowledgeBase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    file: msg.getFile_asB64(),
    fileName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    source: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.KnowledgeBase}
 */
proto.questionnaire.KnowledgeBase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.KnowledgeBase;
  return proto.questionnaire.KnowledgeBase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.KnowledgeBase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.KnowledgeBase}
 */
proto.questionnaire.KnowledgeBase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnswerCount(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 9:
      var value = /** @type {!proto.questionnaire.KNOWLEDGE_BASE_SOURCE} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.KnowledgeBase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.KnowledgeBase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.KnowledgeBase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.KnowledgeBase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAnswerCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.KnowledgeBase.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 question_count = 2;
 * @return {number}
 */
proto.questionnaire.KnowledgeBase.prototype.getQuestionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setQuestionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 answer_count = 3;
 * @return {number}
 */
proto.questionnaire.KnowledgeBase.prototype.getAnswerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setAnswerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.KnowledgeBase.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
*/
proto.questionnaire.KnowledgeBase.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.KnowledgeBase.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes file = 5;
 * @return {!(string|Uint8Array)}
 */
proto.questionnaire.KnowledgeBase.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes file = 5;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.questionnaire.KnowledgeBase.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.questionnaire.KnowledgeBase.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string file_name = 6;
 * @return {string}
 */
proto.questionnaire.KnowledgeBase.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content_id = 7;
 * @return {string}
 */
proto.questionnaire.KnowledgeBase.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_email = 8;
 * @return {string}
 */
proto.questionnaire.KnowledgeBase.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional KNOWLEDGE_BASE_SOURCE source = 9;
 * @return {!proto.questionnaire.KNOWLEDGE_BASE_SOURCE}
 */
proto.questionnaire.KnowledgeBase.prototype.getSource = function() {
  return /** @type {!proto.questionnaire.KNOWLEDGE_BASE_SOURCE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.questionnaire.KNOWLEDGE_BASE_SOURCE} value
 * @return {!proto.questionnaire.KnowledgeBase} returns this
 */
proto.questionnaire.KnowledgeBase.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.IndexQuestionnaire.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.IndexQuestionnaire.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.IndexQuestionnaire} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.IndexQuestionnaire.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    organization: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    acceptance: jspb.Message.getFieldWithDefault(msg, 7, 0),
    assignee: jspb.Message.getFieldWithDefault(msg, 8, ""),
    questionCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    manualResponse: jspb.Message.getFieldWithDefault(msg, 10, 0),
    automatedResponse: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.IndexQuestionnaire}
 */
proto.questionnaire.IndexQuestionnaire.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.IndexQuestionnaire;
  return proto.questionnaire.IndexQuestionnaire.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.IndexQuestionnaire} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.IndexQuestionnaire}
 */
proto.questionnaire.IndexQuestionnaire.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} */ (reader.readEnum());
      msg.setAcceptance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignee(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualResponse(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutomatedResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.IndexQuestionnaire.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.IndexQuestionnaire.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.IndexQuestionnaire} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.IndexQuestionnaire.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAcceptance();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getAssignee();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuestionCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getManualResponse();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAutomatedResponse();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
*/
proto.questionnaire.IndexQuestionnaire.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.IndexQuestionnaire.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string organization = 4;
 * @return {string}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
*/
proto.questionnaire.IndexQuestionnaire.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.IndexQuestionnaire.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional QUESTIONNAIRE_STATUS status = 6;
 * @return {!proto.questionnaire.QUESTIONNAIRE_STATUS}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getStatus = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_STATUS} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_STATUS} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional QUESTIONNAIRE_ACCEPTANCE acceptance = 7;
 * @return {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getAcceptance = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setAcceptance = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string assignee = 8;
 * @return {string}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getAssignee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 question_count = 9;
 * @return {number}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getQuestionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setQuestionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 manual_response = 10;
 * @return {number}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getManualResponse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setManualResponse = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 automated_response = 11;
 * @return {number}
 */
proto.questionnaire.IndexQuestionnaire.prototype.getAutomatedResponse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.IndexQuestionnaire} returns this
 */
proto.questionnaire.IndexQuestionnaire.prototype.setAutomatedResponse = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.QuestionnaireIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.QuestionnaireIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.QuestionnaireIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.QuestionnaireIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.QuestionnaireIdResponse}
 */
proto.questionnaire.QuestionnaireIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.QuestionnaireIdResponse;
  return proto.questionnaire.QuestionnaireIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.QuestionnaireIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.QuestionnaireIdResponse}
 */
proto.questionnaire.QuestionnaireIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.QuestionnaireIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.QuestionnaireIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.QuestionnaireIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.QuestionnaireIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.QuestionnaireIdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.QuestionnaireIdResponse} returns this
 */
proto.questionnaire.QuestionnaireIdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.AnswerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.AnswerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.AnswerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.AnswerIdResponse}
 */
proto.questionnaire.AnswerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.AnswerIdResponse;
  return proto.questionnaire.AnswerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.AnswerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.AnswerIdResponse}
 */
proto.questionnaire.AnswerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.AnswerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.AnswerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.AnswerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.AnswerIdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.AnswerIdResponse} returns this
 */
proto.questionnaire.AnswerIdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.AnswerAgainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.AnswerAgainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.AnswerAgainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerAgainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && proto.questionnaire.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.AnswerAgainRequest}
 */
proto.questionnaire.AnswerAgainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.AnswerAgainRequest;
  return proto.questionnaire.AnswerAgainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.AnswerAgainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.AnswerAgainRequest}
 */
proto.questionnaire.AnswerAgainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.Question;
      reader.readMessage(value,proto.questionnaire.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.AnswerAgainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.AnswerAgainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.AnswerAgainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerAgainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.questionnaire.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional Question question = 1;
 * @return {?proto.questionnaire.Question}
 */
proto.questionnaire.AnswerAgainRequest.prototype.getQuestion = function() {
  return /** @type{?proto.questionnaire.Question} */ (
    jspb.Message.getWrapperField(this, proto.questionnaire.Question, 1));
};


/**
 * @param {?proto.questionnaire.Question|undefined} value
 * @return {!proto.questionnaire.AnswerAgainRequest} returns this
*/
proto.questionnaire.AnswerAgainRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.AnswerAgainRequest} returns this
 */
proto.questionnaire.AnswerAgainRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.AnswerAgainRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.AnswerAgainResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.AnswerAgainResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.AnswerAgainResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerAgainResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.AnswerAgainResponse}
 */
proto.questionnaire.AnswerAgainResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.AnswerAgainResponse;
  return proto.questionnaire.AnswerAgainResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.AnswerAgainResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.AnswerAgainResponse}
 */
proto.questionnaire.AnswerAgainResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.AnswerAgainResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.AnswerAgainResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.AnswerAgainResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AnswerAgainResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string question_id = 1;
 * @return {string}
 */
proto.questionnaire.AnswerAgainResponse.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.AnswerAgainResponse} returns this
 */
proto.questionnaire.AnswerAgainResponse.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.questionnaire.GetQuestionnairesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.GetQuestionnairesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.GetQuestionnairesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.GetQuestionnairesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetQuestionnairesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    proto.questionnaire.Questionnaire.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.GetQuestionnairesResponse}
 */
proto.questionnaire.GetQuestionnairesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.GetQuestionnairesResponse;
  return proto.questionnaire.GetQuestionnairesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.GetQuestionnairesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.GetQuestionnairesResponse}
 */
proto.questionnaire.GetQuestionnairesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.Questionnaire;
      reader.readMessage(value,proto.questionnaire.Questionnaire.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.GetQuestionnairesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.GetQuestionnairesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.GetQuestionnairesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetQuestionnairesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.questionnaire.Questionnaire.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Questionnaire questionnaires = 1;
 * @return {!Array<!proto.questionnaire.Questionnaire>}
 */
proto.questionnaire.GetQuestionnairesResponse.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.questionnaire.Questionnaire>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.questionnaire.Questionnaire, 1));
};


/**
 * @param {!Array<!proto.questionnaire.Questionnaire>} value
 * @return {!proto.questionnaire.GetQuestionnairesResponse} returns this
*/
proto.questionnaire.GetQuestionnairesResponse.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.questionnaire.Questionnaire=} opt_value
 * @param {number=} opt_index
 * @return {!proto.questionnaire.Questionnaire}
 */
proto.questionnaire.GetQuestionnairesResponse.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.questionnaire.Questionnaire, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.questionnaire.GetQuestionnairesResponse} returns this
 */
proto.questionnaire.GetQuestionnairesResponse.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.Answer_Sources.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.Answer_Sources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.Answer_Sources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Answer_Sources.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kbQuestion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kbName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chunkHash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.Answer_Sources}
 */
proto.questionnaire.Answer_Sources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.Answer_Sources;
  return proto.questionnaire.Answer_Sources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.Answer_Sources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.Answer_Sources}
 */
proto.questionnaire.Answer_Sources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKbQuestion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKbName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChunkHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.Answer_Sources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.Answer_Sources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.Answer_Sources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Answer_Sources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKbQuestion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKbName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChunkHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.Answer_Sources.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer_Sources} returns this
 */
proto.questionnaire.Answer_Sources.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kb_question = 2;
 * @return {string}
 */
proto.questionnaire.Answer_Sources.prototype.getKbQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer_Sources} returns this
 */
proto.questionnaire.Answer_Sources.prototype.setKbQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kb_name = 3;
 * @return {string}
 */
proto.questionnaire.Answer_Sources.prototype.getKbName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer_Sources} returns this
 */
proto.questionnaire.Answer_Sources.prototype.setKbName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string chunk_hash = 4;
 * @return {string}
 */
proto.questionnaire.Answer_Sources.prototype.getChunkHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer_Sources} returns this
 */
proto.questionnaire.Answer_Sources.prototype.setChunkHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.questionnaire.Question.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    question: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    acceptance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    canAnswer: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    type: jspb.Message.getFieldWithDefault(msg, 8, 0),
    questionnaireId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    inProgress: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    answerCreatedAt: (f = msg.getAnswerCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.questionnaire.Answer_Sources.toObject, includeInstance),
    wallTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.Question}
 */
proto.questionnaire.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.Question;
  return proto.questionnaire.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.Question}
 */
proto.questionnaire.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 4:
      var value = /** @type {!proto.questionnaire.ANSWER_ACCEPTANCE} */ (reader.readEnum());
      msg.setAcceptance(value);
      break;
    case 5:
      var value = /** @type {!proto.questionnaire.ANSWER_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanAnswer(value);
      break;
    case 8:
      var value = /** @type {!proto.questionnaire.ANSWER_TYPE} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInProgress(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnswerCreatedAt(value);
      break;
    case 12:
      var value = new proto.questionnaire.Answer_Sources;
      reader.readMessage(value,proto.questionnaire.Answer_Sources.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWallTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAcceptance();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAnswerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCanAnswer();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getQuestionnaireId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInProgress();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAnswerCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.questionnaire.Answer_Sources.serializeBinaryToWriter
    );
  }
  f = message.getWallTime();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.Question.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question = 2;
 * @return {string}
 */
proto.questionnaire.Question.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string answer = 3;
 * @return {string}
 */
proto.questionnaire.Question.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ANSWER_ACCEPTANCE acceptance = 4;
 * @return {!proto.questionnaire.ANSWER_ACCEPTANCE}
 */
proto.questionnaire.Question.prototype.getAcceptance = function() {
  return /** @type {!proto.questionnaire.ANSWER_ACCEPTANCE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.questionnaire.ANSWER_ACCEPTANCE} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setAcceptance = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ANSWER_STATUS status = 5;
 * @return {!proto.questionnaire.ANSWER_STATUS}
 */
proto.questionnaire.Question.prototype.getStatus = function() {
  return /** @type {!proto.questionnaire.ANSWER_STATUS} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.questionnaire.ANSWER_STATUS} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string answer_id = 6;
 * @return {string}
 */
proto.questionnaire.Question.prototype.getAnswerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool can_answer = 7;
 * @return {boolean}
 */
proto.questionnaire.Question.prototype.getCanAnswer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setCanAnswer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ANSWER_TYPE type = 8;
 * @return {!proto.questionnaire.ANSWER_TYPE}
 */
proto.questionnaire.Question.prototype.getType = function() {
  return /** @type {!proto.questionnaire.ANSWER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.questionnaire.ANSWER_TYPE} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string questionnaire_id = 9;
 * @return {string}
 */
proto.questionnaire.Question.prototype.getQuestionnaireId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setQuestionnaireId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool in_progress = 10;
 * @return {boolean}
 */
proto.questionnaire.Question.prototype.getInProgress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setInProgress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp answer_created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.Question.prototype.getAnswerCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.Question} returns this
*/
proto.questionnaire.Question.prototype.setAnswerCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.clearAnswerCreatedAt = function() {
  return this.setAnswerCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.Question.prototype.hasAnswerCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Answer_Sources sources = 12;
 * @return {!Array<!proto.questionnaire.Answer_Sources>}
 */
proto.questionnaire.Question.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.questionnaire.Answer_Sources>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.questionnaire.Answer_Sources, 12));
};


/**
 * @param {!Array<!proto.questionnaire.Answer_Sources>} value
 * @return {!proto.questionnaire.Question} returns this
*/
proto.questionnaire.Question.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.questionnaire.Answer_Sources=} opt_value
 * @param {number=} opt_index
 * @return {!proto.questionnaire.Answer_Sources}
 */
proto.questionnaire.Question.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.questionnaire.Answer_Sources, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};


/**
 * optional double wall_time = 13;
 * @return {number}
 */
proto.questionnaire.Question.prototype.getWallTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.Question} returns this
 */
proto.questionnaire.Question.prototype.setWallTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.UploadKnowledgeBaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadKnowledgeBaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    file: msg.getFile_asB64(),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.UploadKnowledgeBaseRequest;
  return proto.questionnaire.UploadKnowledgeBaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnswerCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.UploadKnowledgeBaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadKnowledgeBaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAnswerCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bytes file = 1;
 * @return {!(string|Uint8Array)}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getFile = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes file = 1;
 * This is a type-conversion wrapper around `getFile()`
 * @return {string}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFile()));
};


/**
 * optional bytes file = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFile()`
 * @return {!Uint8Array}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest} returns this
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.setFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest} returns this
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 question_count = 3;
 * @return {number}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getQuestionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest} returns this
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.setQuestionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 answer_count = 4;
 * @return {number}
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.getAnswerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.questionnaire.UploadKnowledgeBaseRequest} returns this
 */
proto.questionnaire.UploadKnowledgeBaseRequest.prototype.setAnswerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.questionnaire.UploadQuestionnaireRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.UploadQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.UploadQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.questionnaire.Question.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isKnowledgeBase: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    questionnaireAnswerType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.UploadQuestionnaireRequest}
 */
proto.questionnaire.UploadQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.UploadQuestionnaireRequest;
  return proto.questionnaire.UploadQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.UploadQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.UploadQuestionnaireRequest}
 */
proto.questionnaire.UploadQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.Question;
      reader.readMessage(value,proto.questionnaire.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsKnowledgeBase(value);
      break;
    case 6:
      var value = /** @type {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} */ (reader.readEnum());
      msg.setQuestionnaireAnswerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.UploadQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.UploadQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.questionnaire.Question.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsKnowledgeBase();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getQuestionnaireAnswerType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * repeated Question questions = 1;
 * @return {!Array<!proto.questionnaire.Question>}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.questionnaire.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.questionnaire.Question, 1));
};


/**
 * @param {!Array<!proto.questionnaire.Question>} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
*/
proto.questionnaire.UploadQuestionnaireRequest.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.questionnaire.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.questionnaire.Question}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.questionnaire.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization = 3;
 * @return {string}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
*/
proto.questionnaire.UploadQuestionnaireRequest.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_knowledge_base = 5;
 * @return {boolean}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getIsKnowledgeBase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.setIsKnowledgeBase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional QUESTIONNAIRE_ANSWER_TYPE questionnaire_answer_type = 6;
 * @return {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE}
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.getQuestionnaireAnswerType = function() {
  return /** @type {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE} value
 * @return {!proto.questionnaire.UploadQuestionnaireRequest} returns this
 */
proto.questionnaire.UploadQuestionnaireRequest.prototype.setQuestionnaireAnswerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.AddSqToKbRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.AddSqToKbRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.AddSqToKbRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AddSqToKbRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    knowledgeBase: (f = msg.getKnowledgeBase()) && proto.questionnaire.UploadKnowledgeBaseRequest.toObject(includeInstance, f),
    securityQuestionnaireId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.AddSqToKbRequest}
 */
proto.questionnaire.AddSqToKbRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.AddSqToKbRequest;
  return proto.questionnaire.AddSqToKbRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.AddSqToKbRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.AddSqToKbRequest}
 */
proto.questionnaire.AddSqToKbRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.UploadKnowledgeBaseRequest;
      reader.readMessage(value,proto.questionnaire.UploadKnowledgeBaseRequest.deserializeBinaryFromReader);
      msg.setKnowledgeBase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityQuestionnaireId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.AddSqToKbRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.AddSqToKbRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.AddSqToKbRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.AddSqToKbRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKnowledgeBase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.questionnaire.UploadKnowledgeBaseRequest.serializeBinaryToWriter
    );
  }
  f = message.getSecurityQuestionnaireId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional UploadKnowledgeBaseRequest knowledge_base = 1;
 * @return {?proto.questionnaire.UploadKnowledgeBaseRequest}
 */
proto.questionnaire.AddSqToKbRequest.prototype.getKnowledgeBase = function() {
  return /** @type{?proto.questionnaire.UploadKnowledgeBaseRequest} */ (
    jspb.Message.getWrapperField(this, proto.questionnaire.UploadKnowledgeBaseRequest, 1));
};


/**
 * @param {?proto.questionnaire.UploadKnowledgeBaseRequest|undefined} value
 * @return {!proto.questionnaire.AddSqToKbRequest} returns this
*/
proto.questionnaire.AddSqToKbRequest.prototype.setKnowledgeBase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.AddSqToKbRequest} returns this
 */
proto.questionnaire.AddSqToKbRequest.prototype.clearKnowledgeBase = function() {
  return this.setKnowledgeBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.AddSqToKbRequest.prototype.hasKnowledgeBase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string security_questionnaire_id = 2;
 * @return {string}
 */
proto.questionnaire.AddSqToKbRequest.prototype.getSecurityQuestionnaireId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.AddSqToKbRequest} returns this
 */
proto.questionnaire.AddSqToKbRequest.prototype.setSecurityQuestionnaireId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.UploadQuestionnaireResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.UploadQuestionnaireResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadQuestionnaireResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionnaireId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.UploadQuestionnaireResponse}
 */
proto.questionnaire.UploadQuestionnaireResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.UploadQuestionnaireResponse;
  return proto.questionnaire.UploadQuestionnaireResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.UploadQuestionnaireResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.UploadQuestionnaireResponse}
 */
proto.questionnaire.UploadQuestionnaireResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.UploadQuestionnaireResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.UploadQuestionnaireResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.UploadQuestionnaireResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionnaireId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.UploadQuestionnaireResponse} returns this
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string questionnaire_id = 2;
 * @return {string}
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.getQuestionnaireId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.UploadQuestionnaireResponse} returns this
 */
proto.questionnaire.UploadQuestionnaireResponse.prototype.setQuestionnaireId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.GetQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.GetQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeDeleted: (f = msg.getIncludeDeleted()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.GetQuestionnaireRequest}
 */
proto.questionnaire.GetQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.GetQuestionnaireRequest;
  return proto.questionnaire.GetQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.GetQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.GetQuestionnaireRequest}
 */
proto.questionnaire.GetQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.GetQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.GetQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.GetQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeDeleted();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.GetQuestionnaireRequest} returns this
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue include_deleted = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.getIncludeDeleted = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.questionnaire.GetQuestionnaireRequest} returns this
*/
proto.questionnaire.GetQuestionnaireRequest.prototype.setIncludeDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.GetQuestionnaireRequest} returns this
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.clearIncludeDeleted = function() {
  return this.setIncludeDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.GetQuestionnaireRequest.prototype.hasIncludeDeleted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.DeleteQuestionnaireRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.DeleteQuestionnaireRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.DeleteQuestionnaireRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.DeleteQuestionnaireRequest}
 */
proto.questionnaire.DeleteQuestionnaireRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.DeleteQuestionnaireRequest;
  return proto.questionnaire.DeleteQuestionnaireRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.DeleteQuestionnaireRequest}
 */
proto.questionnaire.DeleteQuestionnaireRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.DeleteQuestionnaireRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.DeleteQuestionnaireRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.DeleteQuestionnaireRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.DeleteQuestionnaireRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.DeleteQuestionnaireRequest} returns this
 */
proto.questionnaire.DeleteQuestionnaireRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    acceptance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    questionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.Answer}
 */
proto.questionnaire.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.Answer;
  return proto.questionnaire.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.Answer}
 */
proto.questionnaire.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 3:
      var value = /** @type {!proto.questionnaire.ANSWER_ACCEPTANCE} */ (reader.readEnum());
      msg.setAcceptance(value);
      break;
    case 4:
      var value = /** @type {!proto.questionnaire.ANSWER_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAcceptance();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.questionnaire.Answer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer} returns this
 */
proto.questionnaire.Answer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.questionnaire.Answer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer} returns this
 */
proto.questionnaire.Answer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ANSWER_ACCEPTANCE acceptance = 3;
 * @return {!proto.questionnaire.ANSWER_ACCEPTANCE}
 */
proto.questionnaire.Answer.prototype.getAcceptance = function() {
  return /** @type {!proto.questionnaire.ANSWER_ACCEPTANCE} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.questionnaire.ANSWER_ACCEPTANCE} value
 * @return {!proto.questionnaire.Answer} returns this
 */
proto.questionnaire.Answer.prototype.setAcceptance = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ANSWER_STATUS status = 4;
 * @return {!proto.questionnaire.ANSWER_STATUS}
 */
proto.questionnaire.Answer.prototype.getStatus = function() {
  return /** @type {!proto.questionnaire.ANSWER_STATUS} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.questionnaire.ANSWER_STATUS} value
 * @return {!proto.questionnaire.Answer} returns this
 */
proto.questionnaire.Answer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string question_id = 5;
 * @return {string}
 */
proto.questionnaire.Answer.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.questionnaire.Answer} returns this
 */
proto.questionnaire.Answer.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.questionnaire.CreateOrUpdateAnswerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: (f = msg.getAnswer()) && proto.questionnaire.Answer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.questionnaire.CreateOrUpdateAnswerRequest}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.questionnaire.CreateOrUpdateAnswerRequest;
  return proto.questionnaire.CreateOrUpdateAnswerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.questionnaire.CreateOrUpdateAnswerRequest}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.questionnaire.Answer;
      reader.readMessage(value,proto.questionnaire.Answer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.questionnaire.CreateOrUpdateAnswerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.questionnaire.Answer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Answer answer = 1;
 * @return {?proto.questionnaire.Answer}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.getAnswer = function() {
  return /** @type{?proto.questionnaire.Answer} */ (
    jspb.Message.getWrapperField(this, proto.questionnaire.Answer, 1));
};


/**
 * @param {?proto.questionnaire.Answer|undefined} value
 * @return {!proto.questionnaire.CreateOrUpdateAnswerRequest} returns this
*/
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.questionnaire.CreateOrUpdateAnswerRequest} returns this
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.questionnaire.CreateOrUpdateAnswerRequest.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.questionnaire.QUESTIONNAIRE_STATUS = {
  INITIATED: 0,
  ERRORED: 1,
  SCANNING: 2,
  CANCELLING: 3,
  CANCELLED: 4,
  COMPLETED: 5
};

/**
 * @enum {number}
 */
proto.questionnaire.QUESTIONNAIRE_TYPE = {
  KB_ONLY: 0,
  QC_BASIC: 1,
  QC_ADVANCED: 2
};

/**
 * @enum {number}
 */
proto.questionnaire.QUESTIONNAIRE_ACCEPTANCE = {
  IN_PROGRESS: 0,
  ACCEPTED_QUESTIONNAIRE: 1
};

/**
 * @enum {number}
 */
proto.questionnaire.KNOWLEDGE_BASE_SOURCE = {
  UPLOAD: 0,
  QUESTIONNAIRE: 1,
  SAMPLE: 2
};

/**
 * @enum {number}
 */
proto.questionnaire.ANSWER_ACCEPTANCE = {
  PENDING: 0,
  REJECTED: 1,
  ACCEPTED: 2
};

/**
 * @enum {number}
 */
proto.questionnaire.ANSWER_STATUS = {
  USER_PROVIDED: 0,
  GENERATED: 1,
  USER_EDITED: 2
};

/**
 * @enum {number}
 */
proto.questionnaire.ANSWER_TYPE = {
  KB_ANSWER: 0,
  PLATFORM_ANSWER: 1,
  BOTH_ANSWER: 2,
  UNSUCCESSFUL: 3
};

/**
 * @enum {number}
 */
proto.questionnaire.QUESTIONNAIRE_ANSWER_TYPE = {
  KNOWLEDGE_BASE: 0,
  BOTH_PREFER_KB: 1,
  BOTH_PREFER_PLATFORM: 2
};

goog.object.extend(exports, proto.questionnaire);
