import { CONTROL_TEST_NAMES } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'

export interface RoadmapIndexRowType {
  title: string
  description: string
  link: string
  percentage: number
}

export type RoadmapApprovalType = {
  title: string
  subtitle: string
  description: string
  value: boolean
  toggleValue: (type?: RoadmapToggles) => void
  toggleType: RoadmapToggles
  isToggling: boolean
}

export type FRACTION_ELEMENTS = {
  numerator: number
  denominator: number
}

export type RoadmapIndexConfig = Record<RoadmapSteps, RoadmapIndexRowType>

export enum RoadmapSteps {
  InitialScoping = 'initial-scoping',
  PrepareContent = 'prepare-content',
  Operationalize = 'operationalize',
  HandleRequests = 'handle-requests',
  AuditReadiness = 'audit-readiness',
}

export enum RoadmapToggles {
  HasSupportingDocuments = 'hasSupportingDocuments',
  ApprovedSupportingDocuments = 'approvedSupportingDocuments',
  PoliciesApproved = 'policiesApproved',
}

export const ROADMAP_STEPS = Object.values(RoadmapSteps) as RoadmapSteps[]

export const PREPARE_CONTENT_CHECKS = [RoadmapToggles.HasSupportingDocuments]

export const OPERATIONALIZE_CHECKS = [
  RoadmapToggles.ApprovedSupportingDocuments,
  RoadmapToggles.PoliciesApproved,
]

export const RoadmapShowStaticText = Object.freeze({
  InitialScoping: {
    title: 'Initial Scoping',
    subtitle:
      'Your first challenge. Get started on the basics, allowing everyone involved to understand what the basic structures will be and what systems are in scope.',
  },
  PrepareContent: {
    title: 'Prepare Content',
    subtitle:
      'Finalize your policies and control language so you know exactly what compliance should look like for your company.',
  },
  Operationalize: {
    title: 'Operationalize',
    subtitle: 'Define and delegate the work of proving compliance.',
  },
  HandleRequests: {
    title: 'Handle Requests',
    subtitle:
      "Use your auditor's document requests to link evidence to the relevant controls.",
  },
  AuditReadiness: {
    title: 'Audit Readiness',
    subtitle:
      'Use Trustero AI to check that all controls should pass your audit. This involves many tests of your evidence on each control, ensuring that it proves you are doing what your control require.',
  },
})

export const RoadmapApprovalText = Object.freeze({
  Operationalize: {
    PolicyApprovals: {
      title: 'POLICIES APPROVED',
      subtitle:
        'Ensure that each policy document in the platform has been approved and is ready for audit',
      description: 'My organization has approved the policies',
    },
    SupportingDocuments: {
      title: 'SUPPORTING DOCUMENTS APPROVED',
      subtitle:
        'Ensure that each supporting document for your policies has been approved and is ready for audit',
      description: 'My organization has approved the supporting documents',
    },
  },
  PrepareContent: {
    SupportingDocuments: {
      title: 'SUPPORTING DOCUMENTS',
      subtitle:
        'Ensure that each policy has the required supporting documents, such as Incident Response Plan and Asset Inventory',
      description: "I've prepared my supporting documents",
    },
  },
})

export const ROADMAP_MODAL = {
  TITLE: 'Control Matches Policy: Select Controls',
  DESCRIPTION:
    'The scan will check these controls. You will need all controls passing for your audit.',
  SUBMIT: 'Scan Selected Controls',
}

export const PREPARE_CONTENT_TESTS = ['Control Matches Policy']

export type SmartCheckCounts = {
  passing: string[]
  issues: string[]
  notScanned: string[]
}

export const AUDIT_READINESS_TESTS = [
  CONTROL_TEST_NAMES.COMPLETENESS,
  CONTROL_TEST_NAMES.SPOT_CHECK,
]

export const DATE_LIMIT_WARNING =
  'This is over a year ago. Consider updating this date in your settings.'
