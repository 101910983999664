import React, { useCallback, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import queryString from 'query-string'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  AuditRun,
  AuditRunSummaries,
  DeleteAuditRunsRequest,
  GetAuditRunRequest,
  GetHasValidFrameworksForScanRequest,
  GetValidAuditsForScanRequest,
  ListAuditRunsRequest,
  StartAuditRunRequest,
  StopAuditRunRequest,
  GetAccountBalanceResponse,
  GetAuditRunProgressRequest,
  AuditRunProgress,
  StartAuditRunResponse,
  EditControlTestResultRequest,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  Controls,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { AuditBotPromiseClient } from '@trustero/trustero-api-web/lib/audit/auditbot_grpc_web_pb'
import { AuditRecords } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import {
  AuditBotAbsoluteRoutes,
  ControlsAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { useGrpcRevalidateByMethod } from 'src/components'
import { useSwrGrpc } from 'src/components/async/useSwrGrpc'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { ThrobberContext } from 'src/Throbber'
import { GetAuditControlTestRunsRequest } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { GetAuditControlTestRunsResponse } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { useConfirmationModal } from 'src/components/ModalForms/useConfirmationModal'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import log from 'loglevel'
import { useInAudit } from 'src/context/AuditContext'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { useAuth } from 'src/context/authContext'
import { ALLOW_TRANSACTION } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  HardLimitBody,
  SoftLimitBody,
} from 'src/components/Reusable/Cost/Cost.components'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useGetRoleAccessConfig } from 'src/app/AppAuth/AppAuth.hooks'
import { RoadmapServicePromiseClient } from '@trustero/trustero-api-web/lib/roadmap/roadmap_grpc_web_pb'
import {
  ControlQueryParams,
  ControlTabParams,
} from '../Controls/ControlsShowPage/ControlsShowItem/ControlShowItem.constants'
import { useDateLimit } from '../AccountSettings/AccountSettings.hooks'
import { runAuditBotAnimation } from './AuditBot.helpers'

export const useInvalidateAuditReadinessCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await mutateFunc(AuditBotPromiseClient.prototype.getAuditReadiness)
  }, [mutateFunc])
}

export const useInvalidateAuditRunsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(RoadmapServicePromiseClient.prototype.getLiveAuditRuns),
      mutateFunc(AuditBotPromiseClient.prototype.listAuditRuns),
      mutateFunc(AuditBotPromiseClient.prototype.getAuditRun),
      mutateFunc(AuditBotPromiseClient.prototype.getAccountBalance),
      mutateFunc(AuditBotPromiseClient.prototype.getAuditControlTestRuns),
      mutateFunc(AuditBotPromiseClient.prototype.getAuditReadiness),
    ])
  }, [mutateFunc])
}

export const useAuditRuns = (): GrpcResponse<AuditRunSummaries> => {
  const { auditId } = useInAudit()
  const req = new ListAuditRunsRequest()
  auditId && req.setAuditId(new StringValue().setValue(auditId))
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.listAuditRuns,
    req,
  )
  return NewGrpcResponse(response)
}

export const useAuditRun = (runId: string): GrpcResponse<AuditRun> => {
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getAuditRun,
    new GetAuditRunRequest().setAuditRunId(runId),
  )
  return NewGrpcResponse(response)
}

export interface DateRange {
  startDate: Date
  endDate: Date
}

export const useStartAuditRunInContext = (): ((
  controlModelIds?: string[],
  shouldOverride?: boolean,
  controlTests?: string[],
  skipAccountValidation?: boolean,
) => Promise<StartAuditRunResponse | void>) => {
  const { auditId } = useInAudit()
  const startAuditRun = useStartAuditRun()
  const { data } = useDateLimit()
  const dateLimit = data?.getDateLimit()

  const range = auditId
    ? undefined
    : {
        startDate: dateLimit?.toDate() || new Date(),
        endDate: new Date(),
      }

  return async (
    controlModelIds?: string[],
    shouldOverride?: boolean,
    controlTests?: string[],
    skipAccountValidation?: boolean,
  ) => {
    return await startAuditRun(
      auditId,
      range,
      controlModelIds,
      shouldOverride,
      controlTests,
      skipAccountValidation,
    )
  }
}

export const useStartAuditRun = (
  navigateOnSubmit = false,
): ((
  auditId?: string,
  range?: DateRange,
  controlModelIds?: string[],
  shouldOverride?: boolean,
  controlTests?: string[],
  skipAccountValidation?: boolean,
) => Promise<StartAuditRunResponse | void>) => {
  const { events } = useAnalytics()
  const { accountId } = useAuth()
  const { setThrobberState } = useContext(ThrobberContext)
  const mutate = useInvalidateAuditRunsCache()
  const { pageContext } = useParams()
  const navigate = useNavigate()
  const auditBotClient = useAuthorizedGrpcClient(AuditBotPromiseClient)
  const { auditId: currentAuditId } = useInAudit()
  const location = useLocation()

  const softLimit = useConfirmationModal<
    [
      string | undefined,
      DateRange | undefined,
      string[] | undefined,
      boolean | undefined,
      string[] | undefined,
      true,
    ]
  >({
    title: "Let's Keep Trustero AI Running Smoothly!",
    body: <SoftLimitBody />,
    confirmText: 'Continue with scan',
    analyticsEvent: events.V_CONTROL_TEST_SOFT_LIMIT,
    analyticsEventData: {
      accountId,
    },
    onConfirmCB: async (
      auditId,
      dateRange,
      controlModelIds,
      shouldOverride,
      controlTests,
      skipAccountValidation,
    ) => {
      try {
        await fireAuditbot(
          auditId,
          dateRange,
          controlModelIds,
          shouldOverride,
          controlTests,
          skipAccountValidation,
        )
      } catch (err) {
        log.error(err)
      }
    },
  })

  const hardLimit = useConfirmationModal({
    title: 'Time to Get Trustero AI Back in Action!',
    body: <HardLimitBody />,
    hideCancel: true,
    confirmText: 'Dismiss',
    analyticsEvent: events.V_CONTROL_TEST_HARD_LIMIT,
    analyticsEventData: {
      accountId,
    },
  })

  const fireAuditbot = useCallback(
    async (
      auditId?: string,
      range?: DateRange,
      controlModelIds?: string[],
      shouldOverride?: boolean,
      controlTests?: string[],
      skipAccountValidation?: boolean,
    ) => {
      runAuditBotAnimation(setThrobberState)

      if (!skipAccountValidation) {
        const accountValidationResponse =
          await auditBotClient.validateAccountBalance(new Empty())
        const allowControlTest = accountValidationResponse.getAllowControlTest()
        if (allowControlTest === ALLOW_TRANSACTION.HARD_BLOCK) {
          hardLimit()
          return
        } else if (allowControlTest === ALLOW_TRANSACTION.SOFT_BLOCK) {
          softLimit(
            auditId,
            range,
            controlModelIds,
            shouldOverride,
            controlTests,
            true,
          )
          return
        }
      }

      const request = new StartAuditRunRequest()
      if (auditId) {
        request.setAuditId(new StringValue().setValue(auditId))
      }
      if (range) {
        request.setStartDate(Timestamp.fromDate(range.startDate))
        request.setEndDate(Timestamp.fromDate(range.endDate))
      }

      if (controlModelIds) {
        request.setControlIdsList(controlModelIds)
      }
      if (shouldOverride) {
        request.setSkipPreAudit(true)
      }
      if (controlTests) {
        request.setControlTestsList(controlTests)
      }
      log.debug(
        'Starting audit run',
        JSON.stringify(request.toObject()),
        ' with range ',
        JSON.stringify(range),
      )
      const response = await auditBotClient.startAuditRun(request)
      // If the scan is for an audit and we are not in the correct audit context, navigate to the correct context
      const shouldSwitchContext = auditId && currentAuditId !== auditId
      let context = pageContext
      if (shouldSwitchContext) {
        context = `${pageContext?.split(':')[0]}:${auditId}`
      }
      if (navigateOnSubmit) {
        // navigate to the correct audit scan show page if the user wants to navigate on submit
        navigate(
          `/${context}/${
            AuditBotAbsoluteRoutes.SHOW
          }/${response.getAuditRunId()}`,
        )
      } else if (shouldSwitchContext) {
        // otherwise, navigate to the correct audit context
        const pathname = location.pathname.split('/').slice(2).join('/')
        const searchParams = queryString.parse(location.search, {
          arrayFormat: 'bracket',
        })
        let newContextUrl = `/${context}/${pathname}`
        if (pathname.includes(ControlsAbsoluteRoutes.SHOW)) {
          newContextUrl = queryString.stringifyUrl(
            {
              url: newContextUrl,
              query: {
                ...searchParams,
                [ControlQueryParams.DEFAULT_TAB]: ControlTabParams.AUDIT_SCANS,
              },
            },
            { arrayFormat: 'bracket' },
          )
        }
        navigate(newContextUrl)
      }
      await mutate()
      return response
    },
    [
      setThrobberState,
      auditBotClient,
      mutate,
      navigateOnSubmit,
      hardLimit,
      softLimit,
      navigate,
      pageContext,
      currentAuditId,
      location.pathname,
      location.search,
    ],
  )
  return fireAuditbot
}

export const useDeleteAuditRuns = (): ((
  auditRunIds: string[],
) => Promise<void>) => {
  const mutate = useInvalidateAuditRunsCache()
  const auditBotClient = useAuthorizedGrpcClient(AuditBotPromiseClient)

  return async (auditRunIds: string[]) => {
    const req = new DeleteAuditRunsRequest().setAuditRunIdsList(auditRunIds)
    await auditBotClient.deleteAuditRuns(req)
    await mutate()
    return
  }
}

export const useCancelAuditRun = (): ((
  auditRunId: string,
) => Promise<void>) => {
  const mutate = useInvalidateAuditRunsCache()
  const auditBotClient = useAuthorizedGrpcClient(AuditBotPromiseClient)

  return async (auditRunId: string) => {
    const req = new StopAuditRunRequest().setAuditRunId(auditRunId)
    await auditBotClient.stopAuditRun(req)
    await mutate()
    return
  }
}

export const useGetAuditControls = (): ((
  auditId: string,
) => Promise<Controls>) => {
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)

  return async (auditId: string) => {
    const request = new ListControlsRequest().setAuditId(
      new StringValue().setValue(auditId),
    )
    return await modelClient.listControls(request)
  }
}

export const getAuditRunProgress = async (
  client: AuditBotPromiseClient,
  auditRunId: string,
): Promise<AuditRunProgress> =>
  await client.getAuditRunProgress(
    new GetAuditRunProgressRequest().setAuditRunId(auditRunId),
  )

export const useValidAuditsForScan = (
  controlId: string,
): GrpcResponse<AuditRecords> => {
  const { response } = useSwrGrpc(
    AuditBotPromiseClient.prototype.getValidAuditsForScan,
    new GetValidAuditsForScanRequest().setControlId(controlId),
  )
  return NewGrpcResponse(response)
}

export const useHasValidFrameworksForScan = (
  controlId: string,
): GrpcResponse<BoolValue> => {
  const { response } = useSwrGrpc(
    AuditBotPromiseClient.prototype.getHasValidFrameworksForScan,
    new GetHasValidFrameworksForScanRequest().setControlId(controlId),
  )
  return NewGrpcResponse(response)
}

export const useAuditRunsForControl = (
  controlId: string,
  auditId?: string,
): GrpcResponse<GetAuditControlTestRunsResponse> => {
  const {
    routeConfig: { restrictToAuditView },
  } = useGetRoleAccessConfig()
  const req = new GetAuditControlTestRunsRequest().setControlId(controlId)
  if (auditId) req.setAuditId(auditId)

  const { response } = useSwrGrpc(
    AuditBotPromiseClient.prototype.getAuditControlTestRuns,
    req,
    !restrictToAuditView,
  )

  return NewGrpcResponse(response)
}

/**
 * Gets the account balance for control tests.
 */
export const useControlTestAllocations =
  (): GrpcResponse<GetAccountBalanceResponse> => {
    const { response } = useSwrGrpc(
      AuditBotPromiseClient.prototype.getAccountBalance,
      new Empty(),
    )

    return NewGrpcResponse(response)
  }

export const useEditControlTestResult = (): (({
  controlTestId,
  controlTestResult,
  controlTestReason,
}: {
  controlTestId: string
  controlTestResult: boolean
  controlTestReason: string
}) => Promise<void>) => {
  const auditbotClient = useAuthorizedGrpcClient(AuditBotPromiseClient)
  const mutate = useInvalidateAuditRunsCache()

  return async ({ controlTestId, controlTestResult, controlTestReason }) => {
    try {
      const request = new EditControlTestResultRequest()
        .setAuditControlTestId(controlTestId)
        .setIsResultPassing(controlTestResult)
        .setReason(controlTestReason)

      await auditbotClient.editControlTestResult(request)
      await mutate()
    } catch (err) {
      log.error(
        `error when attempting to update audit bot AI Control Check with id: ${controlTestId} with error:`,
        err,
      )
    }
  }
}
