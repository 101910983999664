import React from 'react'
import styled from 'styled-components/macro'
import { color } from 'styled-system'

export const IconContainer = styled.div.attrs({
  className: 'icon',
  bg: 'bg.neutralDark',
})<{ height?: number; width?: number }>`
  ${color}
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ width }) => (width ? width : 40)}px;
  height: ${({ height }) => (height ? height : 40)}px;
  border-radius: 50%;
  background-clip: padding-box;

  svg:not(.progress-ring) {
    width: 24px;
    height: 24px;
    filter: grayscale(1);
    opacity: 0.8;
  }
`

export const IconProgressRing = styled.svg.attrs({
  className: 'progress-ring',
})`
  position: absolute;
  width: ${({ width }) => (width ? width : 56)}px;
  height: ${({ height }) => (height ? height : 56)}px;
`

type AcceptProps = {
  progress: number
  radius?: number
}

type ReturnProps = {
  progress: number
  offsetstart: number
  offsetend: number
}

const ProgressCircle = ({ ...props }) => <circle {...props} />

/**
 * @deprecated Use `<ProgressCircle />` moving forward (/Reusable/ProgressCircle/ProgressCircle.tsx)
 */
export const IconProgressRingCircle = styled(ProgressCircle).attrs<
  AcceptProps,
  ReturnProps
>((props) => {
  const radius = props.radius || 26
  const circumference = radius * 2 * Math.PI

  return {
    ...props,
    r: `${radius}`,
    cx: `${radius + 2}`,
    cy: `${radius + 2}`,
    stroke: props.theme.colors?.fill.status.success.light,
    strokeWidth: '4',
    strokeLinecap: 'round',
    strokeDasharray: `${circumference} ${circumference}`,
    fill: 'transparent',
    offsetstart: circumference,
    offsetend: circumference * (1 - (props.progress || 0)),
  }
})`
  transform: rotate(-90deg);
  transform-origin: center;
  stroke-dashoffset: ${(props) => props.offsetend};

  @keyframes animate_checklist_ring {
    from {
      stroke-dashoffset: ${(props) => `${props.offsetstart}`};
    }
  }

  animation: animate_checklist_ring 0.35s ease 1;
  transition: stroke-dashoffset 0.35s ease;
`
