export const truncate = ({
  text,
  chars = 20,
}: {
  text: string
  chars?: number
}): string =>
  text.length > chars ? `${text.substring(0, chars - 3)}...` : text

export const formatEvidenceCaption = (
  caption: string,
  isAutomated: boolean,
): string => (isAutomated ? caption.replaceAll('--', '-') : caption)
