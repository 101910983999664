import React from 'react'
import { useAnalytics } from '../analytics/useAnalytics'
import { CloseButton } from '../components/Reusable/Buttons/CloseButton'
import { InfoPanelContainer } from './InfoPanel.styles'
import { useInfoPanelContext } from './InfoPanelContext'

export const InfoPanel = (): JSX.Element => {
  const { track, events } = useAnalytics()
  const { infoPanelState, setInfoPanelState } = useInfoPanelContext()
  const { isShown, render } = infoPanelState
  const content = isShown ? render : <></>

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    track(events.V_ACTIVITY_LOG, { toggle: 'close', source: 'panel' })
    setInfoPanelState({
      isShown: false,
      render: <div></div>,
    })
  }

  return (
    <InfoPanelContainer isShown={isShown}>
      <CloseButton
        position="absolute"
        top="s"
        right="s"
        onClick={handleClick}
      />
      {content}
    </InfoPanelContainer>
  )
}
