import { FRAMEWORK_MODEL_IDS } from '../../../../Utils/globalEnums'
import { FilterBarConfig } from './FilterBar.constants'

export type FilterBarProps = {
  config: FilterBarConfig
}

export enum FilterParam {
  DEPARTMENT = 'department_ids',
  POLICY = 'policy_ids',
  STATUS = 'status',
  OWNER = 'owner',
  SERVICE = 'related_service_ids',
  SOC2 = 'soc2_criteria',
  SOC1 = 'soc1_criteria',
  ISO = 'iso_27001_objectives',
  PCISAQD = 'pci_saq_d_objectives',
  PCISAQA = 'pci_saq_a_objectives',
  HITRUST = 'hitrust_objectives',
  HIPAA = 'hipaa_sections',
  NISTCSF = 'nist_csf_requirements',
  NYDFS = 'nydfs',
  ISO27701_DC = 'iso27701_dc',
  ISO27701_DP = 'iso27701_dp',
  GDPR_DC = 'gdpr_dc',
  GDPR_DP = 'gdpr_dp',
  PIPEDA_DC = 'pipeda_dc',
  PIPEDA_DP = 'pipeda_dp',
  TEST = 'test_framework_ids',
  HAS_DOCUMENTATION = 'has_documentation',
  IS_CUSTOMIZED = 'is_customized',
  AUDIT = 'audit',
  COMPLIANCE_FRAMEWORK = 'compliance_framework',
  INHERENT_RISK = 'inherent_risk',
  RESIDUAL_RISK = 'residual_risk',
  RECEPTOR = 'receptor_ids',
  SORT_ORDER = 'sort_order',
  SORT_COLUMN = 'sort_column',
  SERVICE_ROLE = 'service_roles',
  RISK_TIER = 'risk_tier',
  ATTESTATION = 'attestation_status',
  AI_VIEW = 'ai_view',
  AI_CONTROL_CHECKS = 'ai_control_checks',
  CONTROL = 'control_ids',
  REQUEST = 'request_ids',
  SOURCE = 'sources',
  FILE_TYPE = 'file_types',
  DATE_RANGE = 'date_range',
  PAGE = 'page',
}

export enum FilterName {
  DEPARTMENT = 'Department',
  POLICY = 'Policy',
  STATUS = 'Status',
  OWNER = 'Owner',
  SERVICE = 'Service',
  SOC2 = 'SOC 2 Criteria',
  SOC1 = 'SOC 1 Criteria',
  ISO = 'ISO 27001 Controls',
  PCISAQD = 'PCI DSS SAQ D Requirements',
  PCISAQA = 'PCI DSS SAQ A Requirements',
  HITRUST = 'HITRUST e1 BUIDS',
  HIPAA = 'HIPAA sections',
  NISTCSF = 'NIST CSF Requirements',
  NYDFS = 'NYDFS Requirements',
  ISO27701_DC = 'ISO 27701 - Data Controller Controls',
  ISO27701_DP = 'ISO 27701 - Data Processor Controls',
  GDPR_DC = 'GDPR - Data Controller Objectives',
  GDPR_DP = 'GDPR - Data Processor Objectives',
  PIPEDA_DC = 'PIPEDA - Data Controller Objectives',
  PIPEDA_DP = 'PIPEDA - Data Processor Objectives',
  TEST = 'Test Compliance Framework Objectives',
  HAS_DOCUMENTATION = 'Documentation',
  IS_CUSTOMIZED = 'Customized',
  AUDIT = 'Audit',
  COMPLIANCE_FRAMEWORK = 'Framework',
  INHERENT_RISK = 'Inherent Risk',
  RESIDUAL_RISK = 'Residual Risk',
  RECEPTOR = 'Receptor',
  SERVICE_ROLE = 'Service Role',
  RISK_TIER = 'Risk Tier',
  ATTESTATION = 'Attestation',
  AI_CONTROL_CHECKS = 'AI Control Checks',
  SOURCE = 'Source',
  FILE_TYPE = 'File Type',
  CONTROL = 'Linked Control',
  ADDED_BY = 'By',
}

interface FilterValues {
  filterName: string
  filterParam: string
}

export const getFilterValuesByFrameworkModelId = (
  modelId: string,
): FilterValues => {
  switch (modelId) {
    case FRAMEWORK_MODEL_IDS.SOC2:
      return {
        filterName: FilterName.SOC2,
        filterParam: FilterParam.SOC2,
      }
    case FRAMEWORK_MODEL_IDS.SOC1:
      return {
        filterName: FilterName.SOC1,
        filterParam: FilterParam.SOC1,
      }
    case FRAMEWORK_MODEL_IDS.ISO27001:
      return {
        filterName: FilterName.ISO,
        filterParam: FilterParam.ISO,
      }
    case FRAMEWORK_MODEL_IDS.TEST:
      return {
        filterName: FilterName.TEST,
        filterParam: FilterParam.TEST,
      }
    case FRAMEWORK_MODEL_IDS.PCISAQD:
      return {
        filterName: FilterName.PCISAQD,
        filterParam: FilterParam.PCISAQD,
      }
    case FRAMEWORK_MODEL_IDS.PCISAQA:
      return {
        filterName: FilterName.PCISAQA,
        filterParam: FilterParam.PCISAQA,
      }
    case FRAMEWORK_MODEL_IDS.HITRUST:
      return {
        filterName: FilterName.HITRUST,
        filterParam: FilterParam.HITRUST,
      }
    case FRAMEWORK_MODEL_IDS.HIPAA:
      return {
        filterName: FilterName.HIPAA,
        filterParam: FilterParam.HIPAA,
      }
    case FRAMEWORK_MODEL_IDS.NISTCSF:
      return {
        filterName: FilterName.NISTCSF,
        filterParam: FilterParam.NISTCSF,
      }
    case FRAMEWORK_MODEL_IDS.NYDFS:
      return {
        filterName: FilterName.NYDFS,
        filterParam: FilterParam.NYDFS,
      }
    case FRAMEWORK_MODEL_IDS.ISO27701_DC:
      return {
        filterName: FilterName.ISO27701_DC,
        filterParam: FilterParam.ISO27701_DC,
      }
    case FRAMEWORK_MODEL_IDS.ISO27701_DP:
      return {
        filterName: FilterName.ISO27701_DP,
        filterParam: FilterParam.ISO27701_DP,
      }
    case FRAMEWORK_MODEL_IDS.GDPR_DC:
      return {
        filterName: FilterName.GDPR_DC,
        filterParam: FilterParam.GDPR_DC,
      }
    case FRAMEWORK_MODEL_IDS.GDPR_DP:
      return {
        filterName: FilterName.GDPR_DP,
        filterParam: FilterParam.GDPR_DP,
      }
    case FRAMEWORK_MODEL_IDS.PIPEDA_DC:
      return {
        filterName: FilterName.PIPEDA_DC,
        filterParam: FilterParam.PIPEDA_DC,
      }
    case FRAMEWORK_MODEL_IDS.PIPEDA_DP:
      return {
        filterName: FilterName.PIPEDA_DP,
        filterParam: FilterParam.PIPEDA_DP,
      }
    default:
      return {
        filterName: FilterName.COMPLIANCE_FRAMEWORK,
        filterParam: FilterParam.COMPLIANCE_FRAMEWORK,
      }
  }
}
